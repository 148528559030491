import PropTypes from "prop-types";
import { ErrorBoundary } from "./ErrorBoundary";
import { Navbar } from "./Navbar/Navbar";

/**
 * @typedef {import("react-router-dom").Route} Route
 */

/**
 * @typedef Props
 * @property {string} identifier - Identifier for the template.
 * @property {Route} route - Route object.
 * @property {React.ReactNode} children - Child components.
 */

/**
 * `Template` component.
 *
 * @param {Props} props - Component props.
 * @returns {React.Element} The rendered component.
 */
export const Template = ({ identifier, route, children }) => (
  <div className={`screen-root-${identifier} z-10`}>
    <ErrorBoundary>
      <Navbar identifier={identifier} route={route} />
      {children}
    </ErrorBoundary>
  </div>
);

Template.propTypes = {
  identifier: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
