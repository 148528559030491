import propTypes from "prop-types";
import Radium, { StyleRoot } from "radium";
import { pulse } from "react-animations";
const styles = {
  pulse: {
    animation: "x 0.8s ease infinite",
    animationName: Radium.keyframes(pulse, "pulse"),
  },
};

export const Loader = ({ size = "w-64 h-64" }) => {
  return (
    <StyleRoot>
      <div style={styles.pulse}>
        <img className={size} src="/logo.png" />
      </div>
    </StyleRoot>
  );
};

Loader.propTypes = {
  size: propTypes.string,
};

export default Loader;
