class Utils {
  static async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }
  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  static substringAndBeautify(string, count) {
    if (string) {
      if (string.length >= count) {
        return string.substring(0, count) + "…";
      }
    }
    return string;
  }
  static priceBeautify(price) {
    if (typeof price == "string") {
      return price.replace(".", ",");
    } else {
      return price.toFixed(2).toString().replace(".", ",");
    }
  }
  static isUrlValid(url) {
    let linkRegexp = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
    );
    return linkRegexp.test(url);
  }
  static explodeReference(o) {
    if (o) {
      return JSON.parse(JSON.stringify(o));
    } else {
      return;
    }
  }
  static toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  static urlToFile = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };
  static customProductToProduct(customProduct) {
    let images = [];
    if (customProduct.image1) images.push({ thumbnail: customProduct.image1 });
    if (customProduct.image2) images.push({ thumbnail: customProduct.image2 });
    if (customProduct.image3) images.push({ thumbnail: customProduct.image3 });
    let product = {
      id: customProduct.id ? customProduct.id : null,
      images,
      description: customProduct.description,
      permalink: customProduct.link,
      name: customProduct.name,
      price: customProduct.price,
      customer: customProduct.customer ? customProduct.customer : "",
    };
    return product;
  }

  static validateEmail(string) {
    if (!string) return false;
    return string
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
}

export default Utils;
