import PropTypes from "prop-types";
import React from "react";

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorReported: false,
    };
  }

  static getDerivedStateFromError() {
    // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
    return { hasError: true };
  }
  componentDidCatch(error) {
    // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.
    console.error("ERROR BOUNDARY", error);
    //logErrorToMyService(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // Vous pouvez afficher n'importe quelle UI de repli.
      return (
        <div className="flex flex-col justify-center items-center h-screen w-screen hero">
          <img src="/error.png" className="w-auto h-96 mb-10" />
          <span className="text-2xl mb-4">
            Désolé une erreur est survenue !
          </span>
          <span>
            Si le problème persiste, vous pouvez nous signaler l&apos;erreur en
            nous écrivant à{" "}
            <a
              className="text-primary underline"
              href="mailto:amour@elyagaspard.fr"
            >
              !
            </a>
          </span>

          {/*this.state.errorReported ? <span>Un rapport d'erreur a automatiquement été remonté sur nos serveurs.</span> : null*/}

          <a href="/" className="btn btn-primary mt-4">
            Retour à l&apos;accueil
          </a>
        </div>
      );
    }
    return this.props.children;
  }
}
