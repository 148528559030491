import PropTypes from "prop-types";
import React from "react";
import * as BootstrapIcons from "react-icons/bs";
import { Link } from "react-router-dom";

export class ButtonComponent extends React.Component {
  render() {
    const BsIcon = this.props.icon ? BootstrapIcons[this.props.icon] : null;
    const disabled = this.props.disabled ? " btn-disabled " : "";
    const mobileHidden = this.props.mobileHidden ? " hidden " : "";

    if (this.props.to) {
      return (
        <Link
          className={"btn btn-primary btn-md" + disabled}
          disabled={this.props.disabled ? "disabled" : ""}
          variant="success"
          to={this.props.to}
        >
          {BsIcon ? <BsIcon className="text-2xl" /> : null}
          <span className={mobileHidden + "sm:inline"}>{this.props.label}</span>
        </Link>
      );
    }

    return (
      <button
        className={"btn btn-primary btn-md" + disabled}
        disabled={this.props.disabled ? "disabled" : ""}
        onClick={() => {
          this.props.onClick();
        }}
      >
        {BsIcon ? <BsIcon className="text-2xl" /> : null}
        <span className={mobileHidden + "sm:inline"}>{this.props.label}</span>
      </button>
    );
  }
}

ButtonComponent.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  mobileHidden: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

export default ButtonComponent;
