import { toast } from "react-toastify";

export class Storage {
  static setCurrentList(list) {
    try {
      let storage = window.localStorage;
      storage.setItem("currentList", JSON.stringify(list));
      return true;
    } catch (e) {
      console.error(e);
      toast.error(
        "Une erreur de stockage est survenue. Assurez-vous de ne pas être en navigation privée et de disposer de place sur votre appareil."
      );
      return false;
    }
  }

  static getCurrentList() {
    let storage = window.localStorage;
    return JSON.parse(storage.getItem("currentList"));
  }

  static removeCurrentList() {
    let storage = window.localStorage;
    return storage.removeItem("currentList");
  }

  static setNologgedList(list) {
    try {
      let storage = window.localStorage;
      storage.setItem("noLoggedList", JSON.stringify(list));
      return true;
    } catch (e) {
      console.error(e);
      toast.error(
        "Une erreur de stockage est survenue. Assurez-vous de ne pas être en navigation privée et de disposer de place sur votre appareil."
      );
      return false;
    }
  }

  static getNologgedList() {
    let storage = window.localStorage;
    return JSON.parse(storage.getItem("noLoggedList"));
  }

  static removeNologgedList() {
    let storage = window.localStorage;
    return storage.removeItem("noLoggedList");
  }
}

export default Storage;
