import Cookies from "js-cookie";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Components/Loader";
import LoginModal from "./Components/LoginModal";
import NavbarRouting from "./Components/Navbar/NavbarRouting.json";
import { Template } from "./Components/Template";
import history from "./history";
import GetMe from "./Requests/GetMe";
import PostLogin from "./Requests/PostLogin";
import { UserProvider } from "./Utils/UserContext";

// Lazy loading screens
const MyListsScreen = lazy(() => import("./Screens/MyLists/MyLists.screen"));
const CreateScreen = lazy(() => import("./Screens/Create/Create.screen"));
const ListDetailScreen = lazy(() =>
  import("./Screens/ListDetail/ListDetail.screen")
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      navigation: {
        scheme: NavbarRouting,
        updateScheme: this.updateScheme,
      },
      loading: true,
      loginLoading: false,
      loginError: null,
      showLoginModal: false,
    };
  }

  async login(data) {
    if (
      data.email &&
      data.password &&
      data.email !== "" &&
      data.password !== ""
    ) {
      this.setState({ loginLoading: true, loginError: null });
      let responseLogin = await new PostLogin({
        username: data.email,
        password: data.password,
      }).send();
      if (responseLogin.data && responseLogin.data.status !== 200) {
        if (responseLogin.data.status === 403) {
          this.setState({
            loginError: "Identifiant ou mot de passe incorrect",
            loginLoading: false,
          });
        } else {
          this.setState({
            loginError:
              "Une erreur est survenue, veuillez réessayer. Si le problème persiste, contactez-nous via le site ou écrivez-nous à amour@elyagaspard.fr",
            loginLoading: false,
          });
        }
      } else {
        Cookies.set("jwt", responseLogin.token);
        if (window.location.href.includes("create/end")) {
          window.location.reload();
        } else {
          window.location = "/";
        }
      }
    }
  }

  async componentDidMount() {
    //App did mount
    let user = await new GetMe().send();
    if (user && user.id) {
      this.setState({
        user: user,
        loading: false,
      });
    } else if (user && user.code === "rest_not_logged_in") {
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  toggleLoginModal() {
    this.setState({ showLoginModal: !this.state.showLoginModal });
  }

  render() {
    document.title = "Mes listes - Elya & Gaspard";
    return (
      <BrowserRouter history={history}>
        <ToastContainer
          position={window.innerWidth > 768 ? "top-right" : "top-center"}
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {this.state.loading ? (
          <div className="flex flex-1 justify-center items-center h-screen w-full">
            <Loader />
          </div>
        ) : (
          <Suspense
            fallback={
              <div className="flex flex-1 justify-center items-center h-screen w-full">
                <Loader />
              </div>
            }
          >
            <UserProvider value={this.state.user}>
              <Switch>
                <Route exact path="/">
                  <MyListsScreen
                    onToggleLogin={() => this.toggleLoginModal()}
                  />
                </Route>
                <Route
                  path="/create/:id"
                  component={(props) => {
                    return (
                      <Template
                        key={"navbar-" + props.match.params.id}
                        identifier="create"
                        route={props}
                        navigation={props}
                        contextValue={this.state.navigation}
                      >
                        <CreateScreen
                          key={props.match.params.id}
                          onToggleLogin={() => this.toggleLoginModal()}
                          {...props}
                        />
                      </Template>
                    );
                  }}
                />
                <Route
                  path="/list/:id"
                  component={(props) => (
                    <ListDetailScreen key={props.match.params.id} {...props} />
                  )}
                ></Route>
                <Route render={() => <Redirect to="/" />} />
              </Switch>
              <LoginModal
                visible={this.state.showLoginModal}
                onClose={() => {
                  this.toggleLoginModal();
                }}
                onSubmit={(data) => this.login(data)}
                loading={this.state.loginLoading}
                error={this.state.loginError}
              />
            </UserProvider>
          </Suspense>
        )}
      </BrowserRouter>
    );
  }
}

export default App;
