import PropTypes from "prop-types";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export const Backlink = ({ auto, to, external, next, label }) => (
  <Link
    to={to}
    onClick={() => (external ? (window.location = external) : null)}
    className={
      "text-gray-900 border-1 md:border-none border-gray-900 btn btn-ghost btn-neutral " +
      auto
        ? "w-auto h-auto"
        : "h-full flex flex-row items-center justify-start "
    }
  >
    {!next && <BsChevronLeft className="sm:text-lg md:text-md mr-1" />}
    <span className="text-sm hidden md:inline">{label}</span>
    {next && <BsChevronRight className="sm:text-lg md:text-md ml-1" />}
  </Link>
);

Backlink.propTypes = {
  auto: PropTypes.bool,
  to: PropTypes.string,
  external: PropTypes.string,
  next: PropTypes.bool,
  label: PropTypes.string,
};
