import PropTypes from "prop-types";
import React from "react";
import { Storage } from "../../Utils/Storage";
import UserContext from "../../Utils/UserContext";
import Utils from "../../Utils/Utils";
import Button from "../Button";
import { Backlink } from "./Backlink";
import RouteParameters from "./RouteParameters.json";

export class Navbar extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      routeParam: null,
      activeShadow: false,
    };
  }

  handleScroll = () => {
    if (window.scrollY > 0) {
      if (!this.state.activeShadow) this.setState({ activeShadow: true });
    } else {
      if (this.state.activeShadow) this.setState({ activeShadow: false });
    }
  };

  componentDidMount() {
    //On mount we set the context to the state
    this.setState({ user: this.context, routeParam: this.getRouteParam() });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  //Checking for state condition (if a state property is available and not empty)
  checkForCondition(params) {
    if (params.stateCondition) {
      if (!this.state[params.stateCondition]) return true;
      if (
        typeof this.state[params.stateCondition] === "object" &&
        Object.keys(this.state[params.stateCondition]).length === 0
      )
        return true;
    }
    return false;
  }

  getRouteParam() {
    for (const [key, value] of Object.entries(RouteParameters)) {
      if (key === this.props.route.match.path) {
        let finalValue = value;
        if (Array.isArray(value)) {
          finalValue = value.filter((it) => {
            return it.id === this.props.route.match.params.id;
          })[0].params;
        }
        return finalValue;
      }
    }
  }

  getEditingListLabel() {
    if (
      Storage.getCurrentList("currentList") &&
      this.props.identifier === "create"
    ) {
      return Storage.getCurrentList("currentList").name;
    } else {
      return null;
    }
  }

  onClickBacklink(link) {
    if (link.includes("tutoriel")) {
      Storage.removeCurrentList();
    }
    window.location = link;
  }

  generateItem(routeParam) {
    const key = routeParam[0];
    const params = routeParam[1];
    if (this.checkForCondition(params))
      return (
        <div key={key} className="md:flex-1 md:w-48 justify-end">
          <span>&nbsp;</span>
        </div>
      );
    switch (key) {
      case "backlink":
        return (
          <div key={key} className="h-full md:flex-1 md:w-48 justify-start">
            <Backlink
              to={params.link}
              external={params.external_link}
              label={params.label}
              className={`${this.props.identifier}-topleft `}
            />
          </div>
        );
      case "label":
        return (
          <div
            key={key}
            className="w-36 max-h-20  md:w-54 md:flex-1 text-center flex-col justify-center"
          >
            <span className="sm:text-xs max-h-12 overflow-hidden md:text-xl font-medium">
              {this.getEditingListLabel()
                ? Utils.substringAndBeautify(this.getEditingListLabel(), 28)
                : params.label}
            </span>
            {params.sublabel ? (
              <span className="text-sm font-medium">{params.sublabel}</span>
            ) : null}
          </div>
        );
      case "topRight":
        if (params.noNavbar) {
          return (
            <div key={key} className="w-1/6 md:flex-1">
              <span>&nbsp;</span>
            </div>
          );
        }
        if (params.type === "button") {
          return (
            <div key={key} className="md:flex-1 md:w-48 justify-end">
              <Button
                disabled={this.checkForCondition(params)}
                onClick={() => this.onClickBacklink(params.link)}
                icon={params.icon}
                label={params.label}
                mobileHidden
                className={`${this.props.identifier}-topright `}
              />
            </div>
          );
        } else if (params.type === "userState") {
          return this.renderUserState();
        } else {
          return (
            <div key={key} className="md:flex-1 md:w-48 justify-end">
              <Backlink
                next
                to={params.link}
                label={params.label}
                className={`${this.props.identifier}-topleft `}
              />
            </div>
          );
        }
      case "ghost":
      default:
        return (
          <div key={key} className="md:flex-1 md:w-48 justify-end">
            <span>&nbsp;</span>
          </div>
        );
    }
  }

  render() {
    const activeShadow = this.state.activeShadow ? "shadow-md " : "";
    return (
      <div
        tabIndex={0}
        className={
          activeShadow +
          "fixed w-full max-h-20 z-50 pl-2 pr-2 navbar flex flex-row items-center justify-between bg-white"
        }
      >
        {this.state.routeParam
          ? Object.entries(this.state.routeParam).map((param) => {
              return this.generateItem(param);
            })
          : null}
      </div>
    );
  }
}

Navbar.propTypes = {
  identifier: PropTypes.string,
  route: PropTypes.object,
};

export default Navbar;
