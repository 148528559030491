import Cookies from "js-cookie";

class AbstractRequest {
  constructor(method, route, filters = "", body = {}, auth = true) {
    let consumer_client = process.env.REACT_APP_WC_REST_CLIENT;
    let consumer_secret = process.env.REACT_APP_WC_REST_SECRET;
    let wcAuth =
      "&consumer_key=" +
      consumer_client +
      "&consumer_secret=" +
      consumer_secret;
    this._base = process.env.REACT_APP_API_ENDPOINT;
    this._method = method;
    this._body = body;
    this._route = route;
    this._filters = filters;
    this._auth = auth;
    this._authorization = Cookies.get("jwt")
      ? "Bearer " + Cookies.get("jwt")
      : "Bearer " + process.env.REACT_APP_DEFAULT_JWT;
    this._WCAuth = route.includes("wc/v3/products") ? wcAuth : "";
  }

  async send() {
    let headers = new Headers();
    //headers.append("Content-Type", "application/json; charset=utf-8");
    this._auth && headers.append("Authorization", this._authorization);
    let fetchInit = {
      method: this._method,
      headers,
      mode: "cors",
      cache: "default",
    };
    if (this._method === "POST") {
      let formData = new FormData();
      Object.keys(this._body).forEach((parameter) => {
        formData.append(parameter, this._body[parameter]);
      });
      fetchInit.body = formData;
    }
    try {
      let response = await fetch(
        this._base + this._route + this._filters + this._WCAuth,
        fetchInit
      );
      let responseJSON = await response.json();
      return responseJSON;
    } catch (e) {
      console.error(
        "Failed to fetch ressource " +
          this._route +
          " with filters " +
          this._filters
      );
    }
    return null;
  }
}

export default AbstractRequest;
