import PropTypes from "prop-types";
import React from "react";

class LoginModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
    };

    this.passwordInput = React.createRef();
  }

  handleSubmit(event) {
    this.props.onSubmit(this.state);
    event.preventDefault();
  }

  render() {
    let classVisible = this.props.visible ? "modal modal-open" : "modal";
    let btnLoading = this.props.loading ? "loading" : "";
    let inputError =
      this.props.error !== null ? "input-error" : "input-primary";
    return (
      <div
        id="login-modal"
        className={classVisible + " z-50"}
        onClick={() => (this.props.onClose ? this.props.onClose() : null)}
      >
        <div
          className="modal-box max-w-full md:w-10/12 md:h-9/10 md:m-4 overflow-auto"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <label
            onClick={() => (this.props.onClose ? this.props.onClose() : null)}
            htmlFor="my-modal-3"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <div className="hero h-full flex flex-col justify-center">
            <div className="hero-content flex-col lg:flex-row-reverse">
              <div className="text-center lg:text-left md:ml-4">
                <h1 className="text-2xl md:text-5xl font-bold">
                  S&apos;identifier sur Elya & Gaspard
                </h1>
                <p className="py-2">
                  Créez un compte sur Elya & Gaspard ou connectez-vous à un
                  compte existant pour sauvegarder, partager et gérer vos
                  listes.
                </p>
                {this.props.error ? (
                  <div className="alert alert-error shadow-lg">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current flex-shrink-0 h-6 w-6 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span>{this.props.error}</span>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="md:card flex-shrink-0 w-full max-w-sm md:shadow-lg md:bg-base-100">
                <form
                  onSubmit={(e) => {
                    this.handleSubmit(e);
                  }}
                  className="card-body"
                >
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Email / Identifiant</span>
                    </label>
                    <input
                      value={this.state.email}
                      disabled={this.props.loading}
                      autoFocus={true}
                      autoComplete="email"
                      placeholder="Votre email ou identifiant"
                      className={`input input-bordered ${inputError}`}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      enterKeyHint="next"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          this.passwordInput.focus();
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Mot de passe</span>
                    </label>
                    <input
                      ref={(ref) => (this.passwordInput = ref)}
                      type="password"
                      disabled={this.props.loading}
                      value={this.state.password}
                      placeholder="Votre mot de passe"
                      className={`input input-bordered ${inputError}`}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      enterKeyHint="done"
                    />
                    <label className="label">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://elyagaspard.fr/mon-compte/motdepasse-perdu/"
                        className="label-text-alt link link-hover"
                      >
                        Mot de passe oublié ?
                      </a>
                    </label>
                  </div>
                  <div className="form-control mt-4">
                    <button
                      type="submit"
                      className={`btn btn-primary ${btnLoading}`}
                      disabled={
                        this.state.email === null ||
                        this.state.email === "" ||
                        this.state.password === null ||
                        this.state.password === ""
                      }
                    >
                      Se connecter
                    </button>
                  </div>
                  <a
                    disabled={this.props.loading}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://elyagaspard.fr/mon-compte/"
                    className="btn btn-sm btn-accent mt-2"
                  >
                    Pas de compte ? S&apos;inscrire
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginModal;
